import React from 'react';
import { graphql } from 'gatsby';
// import cs from 'classnames';
import styles from 'src/layouts/podcast/podcast.module.sass';
import SEO from 'src/components/seo';
import Layout from 'src/Theme/layout';
import Player from 'src/components/Player/Player';
import BackgroundImage from 'gatsby-background-image';


export const query = graphql`
    query podcast($id: String!) {
        datoCmsPodcast(id: {eq: $id}) {
            title
            description
            backgroundImage{
                alt
                fluid(maxWidth: 1500) {
                    ...GatsbyDatoCmsFluid_noBase64
                }

            }
            podcastFile{
                url
            }
            meta {
                createdAt
            }
        }
    }
`;

const Podcast = ({ data }) => {
  const { datoCmsPodcast: { backgroundImage: { fluid } } } = data;
  return (
    <Layout navWhite>
      <SEO title="Podcast"/>
      <BackgroundImage
        Tag={`header`}
        className={styles.header}
        fluid={fluid}
      >
        <div className={styles.layer}></div>
        <Player data={data}/>
      </BackgroundImage>
    </Layout>
  );
};

export default Podcast;